<template>
  <div>
    <div class="modal fade" id="modal-form-excel-tramas">
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Cargar Archivo</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModalTramas"
              @click="limpiarModal()"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>

          <div class="modal-body">
            <div class="row">
              <div class="btn btn-default image-preview-input col-md-10">
                <input
                  type="file"
                  id="file"
                  ref="file"
                  class="form-control btn btn-light"
                  v-on:change="elegirDirectorio()"
                  accept=".XLSX, .CSV"
                  :class="$v.form.file.$invalid ? 'is-invalid' : 'is-valid'"
                />
              </div>
              <div class="form-group col-md-2 float-left" v-if="form.file">
                <button
                  type="button"
                  class="btn btn-secondary"
                  @click="limpiarArchivo"
                >
                  <i class="fas fa-eraser"></i>
                </button>
              </div>
            </div>
            <br />
            <div class="row">
              <div class="form-group col-md-10">
                <div class="card card-outline card-navy">
                  <div class="card-header pt-2 pb-2">
                    <h3 class="card-title">
                      <h6>
                        <i class="fas fa-file-signature"></i>
                        Nota:
                      </h6>
                    </h3>
                  </div>
                  <div class="card-body">
                    <div class="row">
                      <ol>
                        <li>
                          El formato debe estar completo sin ningún campo en
                          blanco.
                        </li>
                        <li>
                          El campo de Fecha Reporte debe llevar un formato
                          (“yyyy–mm-dd hh:mm:ss”).
                        </li>
                        <li>La placa debe estar en mayúscula.</li>
                      </ol>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="row">
              <button
                type="button"
                class="btn btn-primary"
                @click="importExcel()"
                v-show="!$v.form.$invalid"
              >
                Importar
                <i class="fas fa-cloud-upload-alt"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import axios from "axios";
import { required, minLength } from "vuelidate/lib/validators";
export default {
  name: "TifCarguesTramasImport",
  components: {},

  data() {
    return {
      accion: 1,
      cargue_id: null,
      tramas: {},

      form: {
        file: null,
      },
    };
  },
  validations: {
    form: {
      file: {
        required,
      },
    },
  },

  methods: {
    llenarModalImport(cargue) {
      this.tramas = cargue;
    },

    elegirDirectorio() {
      this.form.file = this.$refs.file.files[0];
    },

    importExcel() {
      this.$parent.cargando = true;
      let formData = new FormData();
      formData.append("file", this.form.file);
      formData.append("cargue_id", this.tramas.id);
      formData.append(
        "fecha_ini",
        this.tramas.fecha_ini + " " + this.tramas.hora_ini
      );
      formData.append(
        "fecha_fin",
        this.tramas.fecha_fin + " " + this.tramas.hora_fin
      );
      formData.append("tif_turno_id", this.tramas.tif_turno_id);
      formData.append("v_placa", this.tramas.vehiculo.placa);
      formData.append("vehiculo_id", this.tramas.vehiculo_id);

      axios
        .post("/api/tif/tramasPendientes/importExcel", formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          if (res.data.status == 0) {
            this.$swal({
              icon: "error",
              title: res.data.message,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.$parent.cargando = false;
          } else if (res.data.status == 1) {
            this.$refs.closeModalTramas.click();
            this.limpiarModal();
            this.$parent.getIndex();
            this.$swal({
              icon: "warning",
              title: res.data.message,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3500,
              timerProgressBar: true,
            });
            this.$parent.cargando = false;
          } else {
            this.$refs.closeModalTramas.click();
            this.limpiarModal();
            this.$parent.getIndex();
            this.$swal({
              icon: "success",
              title: "El cargue de tramas se guardaron exitosamente.",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.$parent.cargando = false;
          }
        });
    },

    limpiarArchivo() {
      this.form.file = null;
      document.getElementById("file").value = "";
    },

    limpiarModal() {
      this.form.file = null;
      document.getElementById("file").value = "";
      this.$parent.getIndex();
    },
  },

  mounted() {},
};
</script>
